<script setup lang="ts">
import {
  LeaseRegisterLeaseSearchScope,
  useLeaseLinkSubleaseForm,
  useSearchDocuments,
} from '@register'
import { twMerge } from 'tailwind-merge'

// PROPS
const props = defineProps<{ isSublease: boolean }>()

// EMITS
const emit = defineEmits<{
  close: []
  submit: []
}>()

// FORM
const { form, leaseDocumentId, onSubmit } = useLeaseLinkSubleaseForm(
  toRefs(props),
)

// SEARCH
const { search, isFetching, leaseDocuments, leaseDocumentURI } =
  useSearchDocuments(leaseDocumentId, {
    scope: props.isSublease
      ? LeaseRegisterLeaseSearchScope.HEAD_LEASES
      : LeaseRegisterLeaseSearchScope.SUB_LEASES_WITHOUT_PARENT,
  })

// CLOSE
const confirm = useConfirm()
const onClose = () => {
  confirm.require({
    title: 'Are you sure you want to close?',
    body: props.isSublease
      ? 'The selected headlease will not be linked to this sublease.'
      : 'The selected sublease will not be linked to this headlease.',
    confirm: () => {
      emit('close')
    },
    bypass: () => !form.value?.node.context.state.dirty,
  })
}
const classes = computed(() => {
  const _classes = [
    'max-w-[650px] w-[650px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800',
  ]
  if (leaseDocumentURI.value) _classes.push('max-w-[1100px] w-[95%]')
  return twMerge(_classes)
})
</script>

<template>
  <ModalBase
    :title="
      isSublease
        ? 'Link a headlease to this sublease'
        : 'Link a sublease to this headlease'
    "
    :modal-class="classes"
    :close-on-click="false"
    @confirm="form?.node.submit()"
    @close="onClose"
  >
    <div class="relative flex gap-2">
      <FormKit
        ref="form"
        type="form"
        :actions="false"
        form-class="w-full"
        @submit="onSubmit"
      >
        <FormKit
          type="listbox"
          name="leaseDocumentId"
          :options="leaseDocuments"
          label="Select the lease to link"
          label-by="name"
          track-by="id"
          :loading="isFetching"
          :searchable="true"
          :internal-search="false"
          validation="required"
          @search="search"
        />
      </FormKit>

      <div
        v-if="leaseDocumentURI"
        class="bg-gray-850 mt-4 flex h-[calc(100vh-266px)] h-full min-h-[500px] w-1/2 min-w-[50%] border-2 border-gray-300 px-0 dark:border-gray-800"
      >
        <WebViewer
          class="w-full border-2 border-gray-300 px-0 dark:border-gray-600"
          :class="[
            !leaseDocumentURI &&
              'pointer-events-none cursor-not-allowed opacity-50',
          ]"
          :initial-doc="leaseDocumentURI"
        />
      </div>
    </div>
  </ModalBase>
</template>
