import type { LeaseRegisterLeaseSearchScope } from '@register'

export interface UseSearchDocumentsOptions {
  scope?: LeaseRegisterLeaseSearchScope
}

export const useSearchDocuments = (
  leaseDocumentId: Ref<string | undefined>,
  options: UseSearchDocumentsOptions = {},
) => {
  // OPTIONS
  const { scope } = options

  // GLOBAL
  const companyId = getCurrentDivisionId()

  // SEARCH
  const { search, data, isFetching } = useApiLeaseDocumentsSearch(
    companyId,
    scope,
  )
  const leaseDocuments = computed(() => data.value ?? [])

  // PREVIEW
  const { data: selectedLeaseDocument } = useLeaseDocument(
    companyId,
    leaseDocumentId,
  )
  const leaseDocumentURI = computed(() => {
    if (!selectedLeaseDocument.value?.importDocumentId) return
    const apiURL = useRuntimeConfig().public.apiURL
    return `${apiURL}/companies/${companyId}/importDocuments/${selectedLeaseDocument.value.importDocumentId}/pdf`
  })
  /* === SELECTED LEASE === */

  return {
    // SEARCH
    search,
    isFetching,
    leaseDocuments,
    // PREVIEW
    leaseDocumentURI,
  }
}
