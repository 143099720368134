/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LeaseRegisterLeaseSearchScope {
    HEAD_LEASES = 'headLeases',
    SUB_LEASES = 'subLeases',
    SUB_LEASES_WITHOUT_PARENT = 'subLeasesWithoutParent',
    ALL = 'all',
}
