import type { FormKitNode } from '@formkit/core'
import type { ToRefs } from 'vue'
import { useApiLeaseLinkSublease } from '@register'

export const useLeaseLinkSubleaseForm = (
  props: ToRefs<{ isSublease: boolean }>,
) => {
  const { isSublease } = props
  const companyId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()

  const form = ref<{ node: FormKitNode }>()
  const leaseDocumentId = computed(
    () =>
      form.value?.node.context._value?.leaseDocumentId as string | undefined,
  )

  const { mutateAsync: linkSublease } = useApiLeaseLinkSublease(companyId)

  const onSubmit = useSubmitHandler<{ leaseDocumentId: string }>(
    (data) =>
      linkSublease({
        subleaseId: isSublease.value ? leaseId : data.leaseDocumentId,
        parentId: isSublease.value ? data.leaseDocumentId : leaseId,
      }),
    {
      shouldEmit: true,
      loadingMessage: isSublease.value
        ? 'Linking to headlease'
        : 'Linking to sublease...',
      successMessage: isSublease.value
        ? 'Linked to headlease'
        : 'Linked to sublease',
      errorMessage: isSublease.value
        ? 'Failed to link to headlease'
        : 'Failed to link to sublease',
    },
  )

  return {
    form,
    leaseDocumentId,
    onSubmit,
  }
}
